import React, {  useContext } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SeparatorSVG from "../assets/images/separator.svg";
import AppContext from "../contexts/AppContext";
import Host from "../components/Planning/PlanningHost";

const Videos = (props) => {
  const { subheading, heading, content, videos } = props;
  const { setShowModal, setModalVideo } = useContext(AppContext);

  return (
    <div id="videos" className="bg-powder  py-28 lg:py-32">
      <div className="container">
        <header className="max-w-xl text-center mx-auto prose">
          <p className="text-azure uppercase text-14px font-bold">
            {subheading}
          </p>
          <h3 className="my-6 mx-auto max-w-md">{heading}</h3>
          <div
            className="text-iron text-16px lg:text-18px"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <img src={SeparatorSVG} alt="separator" className="mx-auto mt-12" />
        </header>

        <section className="">
          {videos.map((video, i) => (
            <div
              key={`video${i}`}
              className={`pb-12 mb-12 md:px-8 xl:px-20 border-b border-pearl ${i === 0 && 'pt-12 mt-12 border-t'}`}
              onClick={()=> {
                setModalVideo(video.video);
                setShowModal(true);
              }}
            >
              <div className="grid md:grid-cols-2 gap-12 items-center">
                <div>
                  <div className="max-w-xl">
                    <h4 className="text-space text-20px font-serif font-medium mb-3 max-w-lg">
                      {video.heading}
                    </h4>
                    <div
                      className="prose prose-sm text-14px text-iron mb-6 max-w-lg"
                      style={{color:'#575664'}}
                      dangerouslySetInnerHTML={{ __html: video.content }}
                    />
                    <div className="djd">
                      <p className="font-bold text-16px mb-6">Hosted by</p>
                      <div className="flex flex-wrap space-x-2">
                        {video.hosts.map((host) => (
                          <Host
                            key={host.name}
                            image={host.profilePicture}
                            name={host.name}
                            company={host.company}
                            linkedin={host.linkedinLink}
                          />
                        ))}
                      </div>
                    </div>
                    <button className="flex text-12px bg-azure transition hover:bg-navy text-white py-4 px-5 font-bold uppercase focus:outline-none">
                      Watch the session
                    </button>
                  </div>
                </div>
                <div>
                  <div className="cursor-pointer relative max-w-xl mx-auto">
                    <div className="z-10 absolute inset-0 flex flex-col items-center justify-center">
                      <svg
                        width="62"
                        height="62"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="31" cy="31" r="31" fill="#00ADEF" />
                        <path
                          d="M40 31.5l-15.75 9.093V22.407L40 31.5z"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    <GatsbyImage
                      image={getImage(video?.videoPreviewImage?.localFile)}
                      alt={video?.videoPreviewImage?.altText}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Videos;
