import React, { useState, useEffect, useContext, useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import gsap from "gsap";
import AppContext from "../contexts/AppContext";

const Partners = (props) => {
  const { subheading, heading, logos, sectionId } = props;

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      setActiveAnchor(sectionId);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="py-28 lg:py-32 bg-white prose lg:prose-lg"
    >
      <div className="container text-center">
        <p className="text-14px uppercase text-azure font-bold mb-6">
          {subheading}
        </p>
        <h3 className="mb-12">{heading}</h3>
        <div className="max-w-5xl mx-auto grid grid-flow-row grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 sm:gap-12 justify-center items-center">
          {logos.map(({ logo: { altText, localFile } }, index) => {
            const imageSource = getImage(localFile);
            return (
              <div
                key={`${`heelo`}-${index}`}
                className="h-20 flex justify-center items-center"
              >
                <div>
                  <GatsbyImage image={imageSource} alt={altText} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Partners;
