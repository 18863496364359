import React from "react";
import EventDate from "./EventDate";
import CTA from "./CTA";
import Waves from "./Waves";

const Banner = (props) => {
  const { heading, button, date, startTime, endTime, timezone } = props;

  return (
    <div className="bg-navy relative overflow-hidden">
      <div className="container relative overflow-hidden">
        <div className="pt-20 pb-32 lg:px-20 prose lg:prose-lg relative z-10">
          <EventDate
            className="flex text-white"
            date={date}
            startTime={startTime}
            endTime={endTime}
            timezone={timezone}
            reverse
          />
          <h4 className="text-white mt-6 mb-12 lg:w-1/2">{heading}</h4>
          <CTA className="text-14px" filled dark {...button} />
        </div>
      </div>
      <Waves
          background={{ r: 2, g: 41, b: 69, a: 0.06 }}
          stroke={{ h: 0, s: 0, l: 100 }}
          className="w-full absolute left-0 bottom-0 z-0 opacity-50 transform rotate-90 scale-y-negative"
        />
    </div>
  );
};

export default Banner;
