import React, { useState, useEffect, useContext, useRef } from "react";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import gsap from "gsap";
import NumberCard from "./NumberCard";
import AppContext from "../contexts/AppContext";

const Change = (props) => {
  const { subheading, heading, content, card, sectionId } = props;

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0 });

  const [animated, setAnimated] = useState(false);

  const card1 = useRef();
  const card2 = useRef();
  const card3 = useRef();
  const card4 = useRef();

  const animateCards = () => {
    gsap.to([card1.current, card2.current, card3.current, card4.current], {
      duration: 2,
      y: 0,
      opacity: 1,
      stagger: 0.1,
      ease: "power4.out",
    });
    setAnimated(true);
  };

  useEffect(() => {
    if (inView) {
      !animated ? animateCards() : null;
      setActiveAnchor(sectionId);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="py-10 lg:pt-20 lg:pb-32 bg-powder prose lg:prose-lg"
    >
      <div className="container">
        <div className="w-full md:w-2/3 xl:w-1/2">
          <p className="text-14px uppercase text-azure font-bold mb-6">
            {subheading}
          </p>
          <h3 className="mb-6">{heading}</h3>
          <div
            className="text-iron text-16px lg:text-18px"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className="flex flex-col md:flex-row md:mt-12 xlg:mt-0">
          <div className="md:mr-3 mt-20">
            <NumberCard
              forwardRef={card1}
              {...card[0]}
              className="bg-electric"
            />
            <NumberCard forwardRef={card2} {...card[1]} className="bg-azure" />
          </div>
          <div className="md:ml-3">
            <NumberCard
              forwardRef={card3}
              {...card[2]}
              className="bg-sapphire"
            />
            <NumberCard forwardRef={card4} {...card[3]} className="bg-space" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Change;
