import React, { useEffect, useContext } from "react";
import {Link} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import CTA from "./CTA";
import StatsCard from "./StatsCard";
import Waves from "./Waves";
import AppContext from "../contexts/AppContext";

const Introduction = (props) => {
  const {
    subheading,
    heading,
    content,
    button,
    image,
    statsCard,
    sectionId,
  } = props;
  const imageSource = getImage(image.localFile);

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) setActiveAnchor(sectionId);
  }, [inView]);

  return (
    <div
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="w-full pt-28 pb-40 lg:pt-44 lg:pb-44 bg-navy relative prose lg:prose-lg overflow-hidden"
    >
      <div className="container flex flex-col-reverse lg:flex-row items-center justify-start  relative z-10">
        <div className="w-full lg:w-1/3 relative mt-16 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9 lg:aspect-w-3 lg:aspect-h-4">
            <div className="overflow-hidden flex justify-center items-center">
              <GatsbyImage image={imageSource} alt={image?.altText} />
            </div>
          </div>
          <StatsCard
            {...statsCard[0]}
            className="bg-sapphire text-white absolute right-32 -bottom-24 md:left-auto md:right-0 md:bottom-32 lg:-right-10 lg:bottom-40"
          />
          <StatsCard
            {...statsCard[1]}
            className="bg-azure text-white absolute right-0 -bottom-24 md:-bottom-5 lg:-right-32 lg:-bottom-12"
          />
        </div>
        <div className="w-full lg:w-2/5 lg:ml-32">
          <p className="text-14px uppercase text-azure font-bold mb-6">
            {subheading}
          </p>
          <h2 className="text-white">{heading}</h2>
          <div
            className="text-18px lg:text-22px text-white lg:ml-24 my-12 opacity-80"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {/* {button && <Link to={button.url} target={button.target} className="lg:ml-24 text-14px text-white py-5 px-8 font-bold uppercase focus:outline-none border border-white transition hover:bg-white hover:text-navy">{button.title}</Link>} */}
          <CTA className="text-14px lg:ml-24 text-14px text-white py-5 px-8 font-bold uppercase " filled dark outline {...button} />
        </div>
      </div>
      <Waves
        background={{ r: 2, g: 41, b: 69, a: 0.06 }}
        stroke={{ h: 0, s: 0, l: 100 }}
        className="w-full absolute left-0 bottom-0 z-0 transform rotate-90 opacity-50 scale-y-negative"
      />
    </div>
  );
};

export default Introduction;
