import React, {useRef, useEffect, useLayoutEffect, useState} from "react";
import Background from "../../assets/images/2023/intro-bg.png";
import Compass from "../../assets/images/2023/compass - white.svg";
import {
    useViewportScroll,
    useAnimation,
    useTransform,
    motion,
} from "framer-motion";
import Button2022 from "../2022/elements/button";
import {useInView} from "react-intersection-observer";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Introduction2023 = (props) => {
    const {sectionId, subheading, heading, content, button, logo, button2} = props;
    const {scrollYProgress} = useViewportScroll();
    const rotate = useTransform(scrollYProgress, [0, 1], [0, 360]);
    const {ref, start, end} = useRefScrollProgress();
    const typingProgress = useTransform(scrollYProgress, [start, end], [0, 1.3]);

    const inViewOpts = useInView({threshold: 0});
    const ref2 = inViewOpts.ref;
    const inView = inViewOpts.inView;
    const controls = useAnimation();

    const logoSource = getImage(logo.localFile);

    useEffect(() => {
        if (inView) {
            controls.start("open");
        }
    }, [inView]);

    return (
        <section className="overflow-hidden relative bg-darkBlue md:bg-transparent" ref={ref} id={sectionId}>
            <div className="absolute z-0 hidden sm:block w-full h-full bg-bottom bg-stone bg" style={{ backgroundImage: `url(${Background})` }}></div>
            <div className="intro-2023 absolute z-0 hidden sm:block w-full h-full object-cover bottom-0"></div>
            <div className="container px-0 relative z-10 container-2023 flex flex-col md:flex-row">
                <div
                    className="relative before-left before-blue text-white px-6 md:p-12 md:px-12 py-8 md:py-16 lg:py-28 md:flex-1">

                    <h5 className="mb-2 lato-style text-14px md:text-19px">
                        {subheading}
                    </h5>
                    <h2
                        className="font-bluu text-32px md:text-47px leading-47 mb-6 md:mb-12"
                        style={{maxWidth: "420px"}}
                    >
                        {heading}
                    </h2>
                    <div
                        className="md:text-19px lato lato-prose max-w-404"
                        dangerouslySetInnerHTML={{__html: content}}
                    />
                    <hr className="thick-hr mt-6 mb-8 max-w-md md:mt-12 md:mb-16 lg:mt-20 md:mt-24"/>
                    {button && (
                        <Button2022
                            colour="lightBlue-alt"
                            title={button.title}
                            url={button.url}
                            target={button.target}
                            popup={true}
                        />
                    )}
                </div>
                <div className="text-white w-full flex flex-col justify-between items-end md:w-2/5 px-6 md:p-12 md:px-12 py-8 md:py-16 lg:py-28">
                    <div className=" flex justify-end">
                        <img src={Compass} className="w-full max-w-sm" />
                    </div>
                    <div className="flex flex-col md:justify-end items-center  space-y-8 md:space-y-12 lg:pt-6">
                        <GatsbyImage
                            image={logoSource}
                            alt={logo.altText}
                            objectFit="contain"
                            className="object-contain object-center max-w-xs"
                        />
                        {button2 && (
                            <Button2022
                                colour="lightBlue-alt"
                                title={button2.title}
                                url="https://zencargo.com"
                                target="_blank"
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

function useRefScrollProgress(inputRef) {
    const ref = inputRef || useRef();
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }
        const rect = ref.current.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offsetTop = rect.top + scrollTop;
        setStart(offsetTop / document.body.clientHeight);
        setEnd((offsetTop + rect.height) / document.body.clientHeight);
    });
    return {ref, start, end};
}

export default Introduction2023;
