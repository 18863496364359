import React, { useContext, useState, useEffect } from "react";
import HubspotForm from "./HubspotForm";
import CloseSVG from "../assets/images/close.svg";
import AppContext from "../contexts/AppContext";
import ModalVideo from "./ModalVideo";
import { useCookie } from "../utils/useCookie";
import { navigate } from "gatsby";

const Modal = ({ heading, content, hubspotForm }) => {
  const { showModal, setShowModal, modalVideo } = useContext(AppContext);
  let [formSent, setFormSent] =
    typeof window !== "undefined" ? useCookie("formsent5") : [false];

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <div
      className={`${
        showModal ? "flex" : "hidden"
      } fixed inset-0 left-0 z-50 w-full min-h-screen lg:justify-center lg:items-center prose lg:prose-lg`}
    >
      <div
        className={`w-full overflow-y-auto max-h-screen  bg-white border border-pearl relative ${
          formSent == "true" || formSent == true
            ? "max-w-xl flex flex-col justify-center p-10 md:p-8"
            : "p-8 lg:max-w-xl xl:max-h-85"
        }`}
      >
        <img
          src={CloseSVG}
          alt="close"
          className="absolute top-10 right-10 cursor-pointer"
          onClick={toggleModal}
        />
        {modalVideo?.length ? (
          <ModalVideo />
        ) : (
          <>
            <h4 className="mt-10 lg:mt-0">{heading}</h4>
            <div
              className="w-full lg:w-3/4 text-14px text-iron mt-6 mb-12"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <HubspotForm setFormSent={setFormSent} {...hubspotForm} />
          </>
        )}
      </div>
      <div
        className="w-full h-full bg-black opacity-50 absolute top-0 left-0 z-negative"
        onClick={toggleModal}
      ></div>
    </div>
  );
};
export default Modal;
