import React from "react";
import ClockSVG from "../assets/images/clock.svg";

const EventTime = ({
  className,
  reverse,
  startTime,
  endTime,
  timezone,
}) => {


  return (
    <div className={`${className} items-center `}>
      <p className="flex items-center prose uppercase text-12px lg:text-18px font-black lato-style">
        <span className={`${reverse ? "text-white" : ""}`}>
          {startTime} - {endTime} {timezone && `- ${timezone}`}
        </span>
      </p>
    </div>
  )
};

export default EventTime;
