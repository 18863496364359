import React from "react";
import background from "../../assets/images/2023/agenda-bg.png";

const Agenda2022 = (props) => {
  const { sectionId, subheading, heading, content, agendaItems } = props;

  return (
    <section className="overflow-hidden">
        {!subheading && !heading && !content ? null : (
            <div className="w-full text-white bg-darkBlue md:bg-stone py-12 md:py-14 relative">
                <div className="stone-overlay"></div>
                <div className="container container-2022 relative z-10">
                    <h5 className="mb-2 lato-style text-14px md:text-19px">
                        {subheading}
                    </h5>
                    <h2 className="font-bluu text-32px md:text-47px leading-47 mb-6 md:mb-10">
                        {heading}
                    </h2>
                    <div
                        className="md:text-19px lato lato-prose max-w-48rem"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
                <img
                    className="absolute hidden sm:block h-full opacity-20 md:opacity-100 md:top-0 bottom-0 right-0"
                    src={background}
                    alt="pattern"
                />
            </div>
        )}
      <div className="container container-2022">
        {agendaItems?.map((agenda, i) => (
          <div key={i} className="flex flex-col md:flex-row">
            <div
              className={`${
                i % 2 == 0
                  ? "before-left before-stone text-darkBlue"
                  : "before-left before-darkblue text-white"
              } pl-0 p-6 w-full w-agenda-left flex items-center relative`}
            >
              <span className="font-bluu text-32px md:text-47px leading-47">
                {agenda.time}
              </span>
            </div>
            <div
              className={`${
                i % 2 == 0 ? "before-right before-stone text-darkBlue" : "before-right before-darkblue text-white"
              } py-8 md:py-16 lg:py-20 md:pl-12 lg:pl-20 w-full md:flex-1 relative`}
            >
              <h3 className="font-bluu text-32px md:text-47px leading-47">
                {agenda.heading}
              </h3>
              <p className="lato lato-style letter-spacing-sm text-14px md:text-16px mb-2 font-bold">
                {agenda.speakerName}
              </p>
              <p className="text-14px md:text-19px lato lato-prose">
                {agenda.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Agenda2022;
