import React from "react";
import PlanningItem from "./PlanningItem";
import PlanningBreak from "./PlanningBreak";

const Planning = ({ className, items }) => {
  return (
    <ul className={`${className} border-t border-pearl prose lg:prose-lg`}>
      {items.map((item, index) => {
        return item.isBreak ? (
          <PlanningBreak key={`${item.startTime}-${index}`} {...item} />
        ) : (
          <PlanningItem key={item.heading} {...item} />
        );
      })}
    </ul>
  );
};

export default Planning;
