import React, { useContext, useEffect, useState } from "react";
import AppContext from "../contexts/AppContext";

const CTA = ({ className, filled, dark, small, outline, title, url, target, beyond }) => {
  const { setShowModal } = useContext(AppContext);
  const [addedTickets, setAddedTickets] = useState(false)

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if(window.location.pathname.includes('/typ')){
        setAddedTickets(true)
      }
    }
  }, []);

  let theme = "border border-white transition hover:bg-white hover:text-navy py-5 px-8 ";
  if (filled) theme = "bg-azure transition hover:bg-navy py-5 px-8 ";
  if (dark) theme = "bg-azure transition hover:bg-white hover:text-navy py-5 px-8 ";
  if (small) theme = "bg-azure transition hover:bg-navy py-4 px-5";
  if (outline) theme = "focus:outline-none border border-white transition hover:bg-white hover:text-navy";
  const defaultTheme =
    "text-white font-bold uppercase focus:outline-none";

  return (
    <>
      {addedTickets ? (
        <a
          href={url}
          target={target}
          className={`${theme} ${defaultTheme} ${className}`}>
          {title}
        </a>
      ) : (beyond) ? (
        <a
          href={url}
          target={target}
          className={`${theme} ${defaultTheme} ${className}`}>
          {title}
        </a>
      ) : (
        <button
          className={`${theme} ${defaultTheme} ${className}`}
          onClick={toggleModal}>
          {title}
        </button>
      )}
    </>
  );
};

export default CTA;
