import React, {
  useContext,
} from "react";

import AppContext from "../contexts/AppContext";

const BodyVideoModal = () => {
  const { modalVideo } = useContext(AppContext);

  return (
    <div className="w-full">
    <div style={{position:'relative', paddingBottom:'56.25%'}}>
      <iframe
        style={{width:'100%',height:'100%',position:'absolute',left:'0px',top:'0px'}}
        width="100%"
        height="100%"
        allow="autoplay"
        frameBorder="0"
        src={modalVideo}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    </div>
  );
};

export default BodyVideoModal;
