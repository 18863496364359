import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import slugify from "slugify";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ModalDialog from "./elements/ModalDialog";
import Play from "../../assets/images/2022/Play.svg";
import {
  useViewportScroll,
  useAnimation,
  useTransform,
  motion,
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import PlanningItem from "../Planning/PlanningItem";

const Videos2022 = (props) => {
  const { sectionId, videos } = props;

  return (
    <section className="overflow-hidden" id={sectionId}>
      <div className="text-darkBlue">
        {videos?.map((video, i) => (
          <div key={i} className="border-b-4 border-stone">
            <VideoListing video={video} />
          </div>
        ))}
      </div>
    </section>
  );
};

export const VideoListing = ({
    video
  }) => {

  const [isOpen, setIsOpen] = useState(false);
  const imageSource = getImage(video.thumbnail?.localFile);
  return(
      <div className="container container-2022-lrg flex flex-col md:flex-row md:items-center md:space-between md:space-x-8 lg:space-x-12 xl:space-x-24 py-8 translate-cont">
        <div className={`bg-white mx-auto w-full sm:w-3/4 md:w-1/2 md:flex-1 relative text-center md:text-left`}>
          <h3 className="font-bluu text-32px md:text-47px leading-47 mb-4 lg:mb-6">
            {video.title}
          </h3>
          <p className="lato lato-style letter-spacing-sm text-14px md:text-16px font-bold mb-6 md:mb-16 max-w-md mx-auto md:ml-0">
            {video.speakerNames}
          </p>
          <button
            onClick={() => setIsOpen(true)}
            className={`inline-flex items-center justify-center w-full md:w-auto mx-auto pl-7 pr-6 py-4 rounded-full text-center text-12px md:text-14px uppercase lato-style transition-all duration-300 md:ml-0 bg-gradient-to-r from-darkBlue to-lightBlue text-white`}>
            <span>
              Watch the session
            </span>
          </button>
        </div>
        <div className="relative aspect-vid-thumbnail mx-auto md:mr-0 mt-8 md:mt-0 cursor-pointer md:w-1/2 transition-all duration-200 hover:opacity-60"
         onClick={() => setIsOpen(true)}>
           <div className="absolute inset-0 m-auto w-20 h-20 lg:w-32 lg:h-32">
           <img className="w-full h-full object-contain object-center" src={Play} alt="Play icon" />
          </div>
          <GatsbyImage
            image={imageSource}
            alt={video.thumbnail.altText}
            objectFit="cover"
            className="w-full h-full object-cover object-center z-negative"
            style={{ position: "relative" }}
          />
        </div>
        <ModalDialog video={video.videoEmbed} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
  );
};

export default Videos2022;
