import React from "react";

const NumberCard = ({ number, heading, content, className, forwardRef }) => (
  <div
    ref={forwardRef}
    className={`${className} text-center lg:text-left flex flex-col lg:flex-row justify-between items-center p-12 mb-6 text-white lg:opacity-0 transform lg:translate-y-32`}
  >
    <p className="text-160px font-serif leading-none -mt-16 lg:mr-10 opacity-50">
      {number}
    </p>
    <div>
      <p className="text-22px font-serif font-medium mb-4 mt-5 lg:mt-0">
        {heading}
      </p>
      <div
        className="text-16px"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  </div>
);

export default NumberCard;
