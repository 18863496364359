import React, { useEffect, useContext, useState } from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import Waves from "./Waves";
import LogoIconSVG from "../assets/images/logo-icon.svg";

import TextLink from "./TextLink";
import AppContext from "../contexts/AppContext";
import { Link } from "gatsby";

const Blocks = (props) => {
  const {  sectionId, subheading, heading, blocks } = props;

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      setActiveAnchor(sectionId);
    }
  }, [inView]);

  return (
    <section
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="py-28 lg:py-32 bg-white">
      <div className="container flex flex-col gap-y-14">
        <header className="flex max-w-2xl text-center mx-auto">
          <div className="w-auto pr-8 prose lg:prose">
            {subheading && <p className="text-14px uppercase text-azure font-bold mb-6">{subheading}</p>}
            {heading && <h2 className="mb-4">{heading}</h2>}
          </div>
        </header>
        <div className="grid grid-flow-row grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-8 bg-azure px-14 md:px-24 py-16 text-white relative prose overflow-hidden">
            <div className="relative z-10 max-w-md flex flex-col gap-y-4 items-start">
              {blocks.gridTopLeft.heading && <h3 className="">{blocks.gridTopLeft.heading}</h3>}
              {blocks.gridTopLeft.content && <p className=" text-18px">{blocks.gridTopLeft.content}</p>}
              {blocks.gridTopLeft.button && (
                <Link to={blocks.gridTopLeft.button.url} target={blocks.gridTopLeft.button.target} className="inline-block text-14px bg-navy mt-8  hover:bg-white hover:text-navy transition-colors duration-200 ease-in-out  py-5 px-8 font-bold uppercase focus:outline-none w-auto">{blocks.gridTopLeft.button.title}</Link>
              )}
            </div>
            <Waves
              background={{ r: 26, g: 176, b: 223, a: 0.06 }}
              stroke={{ h: 0, s: 0, l: 100 }}
              className="w-full absolute left-0 bottom-0 z-0 opacity-30 transform rotate-90 scale-y-negative"
            />
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-4  bg-powder prose text-center flex flex-col justify-end relative">
            <StaticImage src={'../assets/images/blocks-illustration.png'} className="w-full h-auto " objectFit="cover" alt="" />
            <div className="relative z-10 flex flex-col gap-y-8 items-center p-14 pt-0 -mt-20">
              <img src={LogoIconSVG} alt="" className="mx-auto w-8 h-auto"  />
              {blocks.gridTopRight.heading && <h4 className="" style={{fontSize: '2.25rem'}}>{blocks.gridTopRight.heading}</h4>}
              {blocks.gridTopRight.link && (
                <TextLink url={blocks.gridTopRight.link.url} target={blocks.gridTopRight.link.target} title={blocks.gridTopRight.link.title}  />
              )}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-4 bg-navy text-white relative">
            <div className="absolute inset-0">
              <div className="absolute z-10 bg-navy opacity-70 inset-0"></div>
              {blocks.gridBottomLeft.image && <GatsbyImage className="w-full h-full " image={getImage(blocks.gridBottomLeft.image.localFile)} alt={blocks.gridBottomLeft.image.altText} />}
            </div>
            <div className="flex flex-col gap-y-4 h-full relative z-10 p-11 prose">
              {blocks.gridBottomLeft.subheading && <h5 className="text-14px uppercase text-azure font-bold">{blocks.gridBottomLeft.subheading}</h5>}
              {blocks.gridBottomLeft.heading && <h4 className="text-white" style={{fontSize: '2.25rem'}}>{blocks.gridBottomLeft.heading}</h4>}
              {blocks.gridBottomLeft.link && (
                <TextLink className="text-white mt-auto" url={blocks.gridBottomLeft.link.url} target={blocks.gridBottomLeft.link.target} title={blocks.gridBottomLeft.link.title}  />
              )}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-4 bg-baby-blue p-11 py-16 prose text-center gap-y-8 flex flex-col justify-end">
            <div className="w-20 mx-auto">
              <svg width="75" height="68" viewBox="0 0 75 68" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-auto">
                <path d="M18.8179 0L1.48562 37.3882L0 51.0065L1.48562 66.3579L9.65656 67.8435L30.4553 66.3579L31.6933 52.9873L30.4553 37.3882L19.3131 36.1502C19.3131 29.7125 25.008 12.6278 28.9697 3.96167L18.8179 0ZM61.901 0L44.5687 37.3882L43.0831 51.0065L44.5687 66.3579L52.7397 67.8435L73.5384 66.3579L74.7764 52.9873L73.5384 37.3882L62.3962 36.1502C62.3962 29.7125 68.0911 12.6278 72.0528 3.96167L61.901 0Z" fill="#1AB0DF"/>
              </svg>
            </div>

            {blocks.gridBottomCentre.testimonial && <h4 style={{fontSize: '1.5rem'}}>{blocks.gridBottomCentre.testimonial}</h4>}
            <div className="flex flex-col gap-y-0.5">
              {blocks.gridBottomCentre.name && <div className="text-14px font-bold">{blocks.gridBottomCentre.name}</div>}
              {blocks.gridBottomCentre.company && <div className="text-14px">{blocks.gridBottomCentre.company}</div>}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-4 bg-navy text-white relative">
            <div className="absolute inset-0">
              <div className="absolute z-10 bg-navy opacity-70 inset-0"></div>
              {blocks.gridBottomRight.image && <GatsbyImage className="w-full h-full " image={getImage(blocks.gridBottomRight.image.localFile)} alt={blocks.gridBottomRight.image.altText} />}
            </div>
            <div className="flex flex-col h-full gap-y-4 relative z-10 p-11 prose">
              {blocks.gridBottomRight.subheading && <h5 className="text-14px uppercase text-azure font-bold">{blocks.gridBottomRight.subheading}</h5>}
              {blocks.gridBottomRight.heading && <h4 className="text-white" style={{fontSize: '2.25rem'}}>{blocks.gridBottomRight.heading}</h4>}
              {blocks.gridBottomRight.link && (
                <TextLink className="text-white mt-auto" url={blocks.gridBottomRight.link.url} target={blocks.gridBottomRight.link.target} title={blocks.gridBottomRight.link.title}  />
              )}
            </div>
          </div>
        </div>
      </div>
        

    </section>
  );
};

export default Blocks;
