import React, { useEffect, useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import { ScrollIndicator } from "./elements/ScrollIndicator";
import Speaker2022 from "./Speaker";
import AppContext from "../../contexts/AppContext";

const Speakers2022 = (props) => {
  const { heading, speakers, sectionId = "slider-2022" } = props;

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0.5 });

  SwiperCore.use([FreeMode]);

  useEffect(() => {
    if (inView) {
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id={slugify(sectionId ? sectionId : "", {
        lower: true,
        strict: true,
      })}
      className="bg-white py-16 lg:py-24 overflow-hidden text-darkBlue"
    >
      <div className="container container-2022">
        <header className="flex">
          <div className="w-auto pr-8">
            {heading && (
              <h2 className="font-bluu text-32px md:text-47px leading-47 text-darkBlue">
                {heading}
              </h2>
            )}
          </div>
        </header>
        <Swiper
          freeMode
          className="w-full flex flex-col relative mt-12 lg:mt-16"
          slidesPerView={`auto`}
          grabCursor="1"
          freeMode={true}
          style={{ overflow: `visible` }}
        >
          {speakers.map(({ name, image, information }, index) => (
            <SwiperSlide
              key={name}
              style={{ maxWidth: "345px" }}
              className={`mr-12 last:mr-0 flex flex-col justify-center h-full`}
            >
              <Speaker2022
                name={name}
                image={image}
                information={information}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="pt-4 md:pt-12 text-center">
        <ScrollIndicator horizontal={true} />
      </div>
    </div>
  );
};

export default Speakers2022;
