import React from "react";

const Quote2022 = (props) => {
  const { sectionId, quote, name, roleAndCompany } = props;
  // console.log(props);
  return (
    <section className="overflow-hidden">
      <div className="w-full text-darkBlue bg-stone py-12 md:py-16 lg:py-28 relative">
        <div className="stone-overlay-rot"></div>
        <div className="container container-2022 relative z-10">
          <p
            className="font-bluu text-32px md:text-47px leading-47 mb-6 md:mb-6 max-w-65rem"
            dangerouslySetInnerHTML={{ __html: quote }}
          />
          <h5 className="lato-style text-14px md:text-19px">
            {name} - {roleAndCompany}
          </h5>
        </div>
      </div>
    </section>
  );
};
export default Quote2022;
