import React, { useEffect } from "react";

const HubspotForm = ({ portalId, formId, region, className, setFormSent }) => {
  const hubSpotEventHandler = (event) => {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      if (event.data.id === formId) {
        setFormSent(true);
      }
    }
  };

  useEffect(() => {
    if ("hbspt" in window) {
      window.hbspt.forms.create({
        region: region,
        portalId: portalId,
        formId: formId,
        target: `#form-${formId}`,
      });
      window.addEventListener("message", hubSpotEventHandler);
    }

    return () => window.removeEventListener("message", hubSpotEventHandler);
  }, []);

  return <div id={`form-${formId}`} className={className}></div>;
};

export default HubspotForm;
