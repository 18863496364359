import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LinkedinSVG from "../assets/images/linkedin.svg";

const Speaker = ({
  profilePicture,
  linkedin,
  name,
  company,
  companyLogo,
  topic,
  startTime,
  endTime,
  forwardRef,
}) => {
  const profileSource = getImage(profilePicture?.localFile);
  const companyLogoSource = getImage(companyLogo?.localFile);

  return (
    <div ref={forwardRef} className="">
      <div className="aspect-w-1 aspect-h-1 relative z-0">
        <div className="overflow-hidden flex justify-center items-center">
          {profileSource && (
            <GatsbyImage
              image={profileSource}
              alt={profilePicture.altText}
              className="w-full min-h-full" 
            />
          )}
        </div>
      </div>
      <div className="w-full relative z-10 pt-8">
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <img src={LinkedinSVG} alt="linkedin" className="mb-3" />
          </a>
        )}
        <div style={{minHeight: '8rem'}}>
          <div className="flex space-x-6 items-center justify-between">
            <div className="flex-1">
              <p className="font-serif font-medium text-20px">{name}</p>
              <p className="font-bold text-16px">{company}</p>
            </div>
            <div className="w-24">
              <div>
                {companyLogoSource && (
                  <GatsbyImage
                    image={companyLogoSource}
                    alt={companyLogo.altText}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 block pt-4 border-t border-pearl">
          <p className="text-14px">
            <span className="font-bold uppercase text-azure">Topic</span>{" "}
            {(startTime && endTime) && (
              <>
                <span className="font-bold mx-2">•</span>
                <span>
                  {startTime} - {endTime}
                </span>
              </>
            )}
          </p>
          <p className="font-bold text-16px mt-2">{topic}</p>
        </div>
      </div>
    </div>
  );
};

export default Speaker;
