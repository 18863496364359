import React from "react";
import moment from 'moment';

const EventDate = ({
  className,
  reverse,
  date,
}) => {

  const dateCorrected = moment(date, 'DD/MM/YYYY').toISOString()


  return (
    <div className={`${className} items-center `}>
      <p className="flex items-center prose uppercase text-12px lg:text-18px font-bold mr-4 lato-style">
        <span className={`${reverse ? "text-white" : ""}`}>{moment(dateCorrected).locale('en-gb').format("Do MMM YYYY")}</span>
      </p>
    </div>
  )
};

export default EventDate;
