import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Speaker2022 = ({
  image,
  name,
  information,
}) => {
  const profileSource = getImage(image?.localFile);

  return (
    <div className="">
      <div className="aspect-w-1 aspect-h-1 relative z-0">
        <div className="overflow-hidden flex justify-center items-center">
          {image && (
            <GatsbyImage
              image={profileSource}
              alt={image?.altText}
              className="w-full min-h-full rounded-2xl"
            />
          )}
        </div>
      </div>
      <div className="w-full relative z-10 pt-8">
        <div style={{ minHeight: "8rem" }}>
          <div className="flex space-x-6 items-center justify-between">
            <div className="flex-1 text-center">
              <p className="font-serif font-medium text-22px md:text-36px leading-47 mb-2">
                {name}
              </p>
              <p className="max-w-220px mx-auto lato text-16px md:text-19px">{information}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speaker2022;
