import { Link } from 'gatsby'
import React from 'react'

export default function TextLink({ className, lineClassName, title, url = '#', target}) {
  const Tag = url.length > 0 && url !== '#' ? Link : 'button';
  return (
    <Tag to={url} target={target} className={`group overflow-hidden text-14px text-current uppercase font-bold inline-flex items-center transition-colors transition-opacity duration-200 ease-in-out focus:outline-none w-auto hover:opacity-50 ${className}`}>
      <div>{title}</div>
      <div className="w-3 h-auto ml-2 transform group-hover:translate-x-4 transition-transform duration-200 ease-in-out">
        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.50378 0.830566L9.17314 4.49993L5.50378 8.16928" stroke="currentColor" strokeWidth="1.52937"/>
          <path d="M9.1734 4.5L0 4.5" stroke="currentColor" strokeWidth="1.52937" strokeLinejoin="round"/>
        </svg>
      </div>
    </Tag>
  )
}
