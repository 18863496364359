import React, { useContext, useState, useEffect } from "react";
import Video from "../../../assets/images/video-icon.svg";
import AppContext from "../../../contexts/AppContext";
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Button2022 = (props) => {
  const { button, typing, typingProgress } = props;

  const { setShowModal } = useContext(AppContext);
  const [addedTickets, setAddedTickets] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.includes("/typ")) {
        setAddedTickets(true);
      }
    }
  }, []);

  let btnClasses = [];
  if (props.colour === "lightBlue") {
    btnClasses.push(
      "border-2 border-lightBlue text-white bg-lightBlue hover:border-darkBlue hover:bg-darkBlue"
    );
  } else if (props.colour === "border") {
    btnClasses.push(
      "border-white border-2 text-white hover:bg-white hover:text-darkBlue"
    );
  } else if (props.colour === "grad-blue") {
    btnClasses.push(
      "md:ml-0 bg-gradient-to-r from-darkBlue to-lightBlue text-white"
    );
  } else if (props.colour === "darkBlue") {
    btnClasses.push(
      "md:ml-0 border-2 border-darkBlue text-white bg-darkBlue hover:border-white hover:bg-white hover:text-darkBlue"
    );
  } else if (props.colour === "lightBlue-alt") {
    btnClasses.push(
      "border-2 border-lightBlue text-white bg-lightBlue hover:border-white hover:bg-white hover:text-lightBlue"
    );
  } else {
    btnClasses.push("");
  }
  return (
    <>
      {props.popup ? (
        <button
          className={
            btnClasses +
            ` inline-flex items-center justify-center w-full md:w-auto mx-auto pl-7 pr-6 py-4 rounded-full text-center text-12px md:text-14px uppercase lato-style transition-all duration-300`
          }
          onClick={toggleModal}
        >
          <span>
            {typing ? (
              <TypingTitle string={props.title} progress={typingProgress} />
            ) : (
              props.title
            )}
          </span>
        </button>
      ) : (
        <a
          href={props.url}
          target={props.target}
          className={
            btnClasses +
            ` inline-flex items-center justify-center w-full md:w-auto mx-auto pl-7 pr-6 py-4 rounded-full text-center text-12px md:text-14px uppercase lato-style transition-all duration-300`
          }
        >
          {props.video && <img className="mr-4" src={Video} alt="video icon" />}
          <span>
            {typing ? (
              <TypingTitle string={props.title} progress={typingProgress} />
            ) : (
              props.title
            )}
          </span>
        </a>
      )}
    </>
  );
};

const TypingTitle = ({ string, progress }) => {
  const split = string.split("");
  const letterOptions = {
    hidden: {opacity: 0},
    visible: {opacity: 1}
  };
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if(inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <>
      {split.map((char, i) => {
        return (
          <motion.span
          ref={ref}
          variants={letterOptions}
          initial="hidden"
          animate={controls}
          transition={{ delay: i/9 }}
            key={i}
            className={`transition-opacity duration-200`}
          >
            {char}
          </motion.span>
        );
      })}
    </>
  );
};

export default Button2022;
