import React, { useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import Planning from "./Planning/Planning";
import SeparatorSVG from "../assets/images/separator.svg";
import AppContext from "../contexts/AppContext";

const Agenda = (props) => {
  const { subheading, heading, content, planningItem, sectionId } = props;
  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0, trackVisibility: true, delay: 100});

  useEffect(() => {
    if (inView) setActiveAnchor(sectionId);
  }, [inView]);

  return (
    <div
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="bg-white"
    >
      <div className="container prose py-28 lg:py-32">
        <div className="text-center max-w-xl mx-auto">
          <p className="text-azure uppercase text-14px font-bold">
            {subheading}
          </p>
          <h3 className="my-6">{heading}</h3>
          <div
            className="text-iron text-16px lg:text-18px"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <img src={SeparatorSVG} alt="separator" className="mx-auto mt-12" />
        </div>
        <Planning className="mt-12 max-w-5xl mx-auto" items={planningItem} />
      </div>
    </div>
  );
};

export default Agenda;
