import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button2022 from "../2022/elements/button";
import Video from "../../assets/images/video-icon.svg";
import { ScrollIndicator } from "./elements/ScrollIndicator";
import ModalDialog from "./elements/ModalDialog";

const Hero2022 = (props) => {
  const {
    heading,
    subheading,
    button,
    heroDate,
    heroTime,
    backgroundType,
    image,
    video,
    popUpLink,
    popupVideo,
    sectionId,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const imageSource = getImage(image?.localFile);
  const videoSource = video?.localFile.url;

  return (
    <section
      id={sectionId}
      className="relative flex flex-col text-white relative pt-32 pb-12 sm:pt-40 lg:h-screen lg:max-h-930 lg:min-h-800"
    >
      <div className="z-10 relative container text-center mb-8 sm:mb-24 lg:my-auto">
        <h1
          className="font-bluu text-h3 md:text-h1 lg:text-85px leading-none mb-6 md:mb-8 max-w-4xl mx-auto"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        {subheading && (
          <h5 className="lato-style text-14px md:text-19px tracking-widest">
            {subheading}
          </h5>
        )}
        <div className="lato-style text-12px md:text-14px flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6 sm:justify-center lg:hidden mt-6">
          <span>{heroDate}</span>
          <span>{heroTime}</span>
        </div>
      </div>
      <div
        className="z-10 relative container mb-8 md:mb-12 lg:mt-8 text-center flex flex-col md:flex-row space-y-3 md:space-y-0"
        style={{ maxWidth: "1312px" }}
      >
        {popUpLink && (
          <div className="lg:absolute left-0 w-full md:w-auto mx-auto lg:ml-4 xl:ml-8">
            <button
              className="border-white border-2 text-white hover:bg-darkBlue inline-flex items-center justify-center w-full md:w-auto mx-auto pl-7 pr-6 py-4 rounded-full text-center text-12px md:text-14px uppercase lato-style transition-all duration-300"
              onClick={() => setIsOpen(true)}
            >
              <img className="mr-4" src={Video} alt="video icon" />
              {popUpLink.title}
            </button>
          </div>
        )}
        {button && (
          <Button2022
            colour="lightBlue"
            title={button.title}
            url={button.url}
            target={button.target}
            popup={true}
          />
        )}
      </div>
      <div className="flex justify-center">
        <ScrollIndicator />
      </div>
      <div className="background-overlay"></div>
      {backgroundType === "video" ? (
        <video
          width="100%"
          height="100vh"
          preload="auto"
          loop
          autoPlay
          muted
          className="absolute inset-0 w-full h-full object-cover object-center z-negative"
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      ) : (
        <GatsbyImage
          image={imageSource}
          alt={image?.altText}
          objectFit="cover"
          className="absolute inset-0 w-full h-full object-cover object-center z-negative"
          style={{ position: "absolute" }}
        />
      )}
      <ModalDialog video={popupVideo} isOpen={isOpen} setIsOpen={setIsOpen} />
    </section>
  );
};
export default Hero2022;
