import React from "react";

const StatsCard = ({ className, number, label }) => (
  <div
    className={`${className} w-32 h-32 lg:w-44 lg:h-44 flex flex-col justify-center items-center`}
  >
    <p className="text-h3 lg:text-80px font-serif leading-none -mt-4 lg:-mt-8 mb-4">
      {number}
    </p>
    <p className="text-14px lg:text-18px font-bold">{label}</p>
  </div>
);

export default StatsCard;
