import React, { useEffect, useRef, useMemo } from "react";

const Waves = ({ className, stroke, background }) => {
  const canvasRef = useRef();
  let canvas, ctx, wave, strokeColor, backgroundColor, increment;

  const initWaves = () => {
    canvas = canvasRef.current;
    ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerWidth;

    wave = {
      y: canvas.width / 1.05,
      length: 0.005,
      amplitude: 325,
      frequency: 0.015,
    };

    strokeColor = {
      h: stroke.h,
      s: stroke.s,
      l: stroke.l,
    };

    backgroundColor = {
      r: background.r,
      g: background.g,
      b: background.b,
      a: background.a,
    };

    increment = wave.frequency;

    window.addEventListener("resize", () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerWidth;
      wave.y = canvas.width / 1.05;
    });
  };

  const simpleWave = () => {
    ctx.fillStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();
    ctx.moveTo(canvas.height, Math.random());

    for (let i = 0; i < canvas.width; i++) {
      const point =
        wave.y +
        Math.cos(i * wave.length + increment) *
          wave.amplitude *
          Math.sin(increment);

      ctx.lineTo(point, i * 1.5);
    }

    ctx.lineWidth = 1;
    ctx.strokeStyle = `hsla(${Math.abs(strokeColor.h)},${strokeColor.s}%,${
      strokeColor.l
    }%, 100`;
    ctx.stroke();
  };

  const reverseWave = () => {
    ctx.fillStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();
    ctx.moveTo(canvas.height, -50);

    for (let i = 0; i < canvas.width; i++) {
      const point =
        wave.y +
        Math.sin(i * wave.length + increment * 2) *
          wave.amplitude *
          Math.cos(increment);

      ctx.lineTo(point, i);
    }

    ctx.lineWidth = 1;
    ctx.strokeStyle = `hsla(${Math.abs(strokeColor.h)},${strokeColor.s}%,${
      strokeColor.l
    }%, 100`;
    ctx.stroke();
  };

  const animate = () => {
    requestAnimationFrame(animate);

    simpleWave();
    reverseWave();

    increment += wave.frequency;
  };

  useEffect(() => {
    initWaves();
    animate();
  }, []);

  return useMemo(()=> <canvas ref={canvasRef} className={className}></canvas>, [canvasRef]);
};

export default Waves;
