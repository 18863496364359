import React from "react";
import PatternSVG from "../../assets/images/pattern.svg";

const PlanningBreak = ({ startTime, endTime }) => (
  <li
    className="p-4 lg:p-6 text-center border-b border-pearl bg-contain"
    style={{ backgroundImage: `url(${PatternSVG})` }}
  >
    <p className="text-14px">
      <span className="font-bold uppercase">Break •</span>{" "}
      {`${startTime} - ${endTime}`}
    </p>
  </li>
);

export default PlanningBreak;
