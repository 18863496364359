import React, {Fragment} from "react";
import { Dialog, Transition } from '@headlessui/react';

const ModalDialog = ({ isOpen, setIsOpen, video }) => {

  const videoSource = [];

  if(video?.localFile){
     videoSource.push(video?.localFile.url);
  } else {
     videoSource.push(video);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} as="div"  className="fixed inset-0 z-30 overflow-y-auto px-6">
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
            {video?.localFile ?
            <div className="relative rounded-lg max-w-xl w-full mx-auto text-center">
              <video width="100%" height="100vh"  preload='auto' controls
                className="w-full h-auto object-contain object-center"
                >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
            :
            <div className="relative rounded-lg overflow-hidden max-w-xl w-full mx-auto text-center aspect-tv">
              <iframe
                width="100%"
                height="100%"
                allow="autoplay"
                frameBorder="0"
                src={videoSource}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            }
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalDialog;