import React from "react";
import scroll from "../../../assets/images/2022/Scroll.svg";
import scrollHorizontal from "../../../assets/images/2022/Scroll-Horizontal.svg";

export const ScrollIndicator = ({ horizontal }) => {
	return (
		<div
			className={`inline-block scroll-pulse ${
				horizontal ? "is-horizontal" : ""
			}`}
		>
			<img
				className="mx-auto"
				src={horizontal ? scrollHorizontal : scroll}
				alt=""
			/>
		</div>
	);
};
