import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TwoColumns2022 = (props) => {
  const { sectionId, subheading, heading, content, listItems, image } = props;

  const imageSource = getImage(image.localFile);
  return (
    <section className="overflow-hidden bg-darkBlue">
      <div className="container container-2022 flex flex-col md:flex-row">
        <div className="relative text-white py-10 md:pr-6 md:py-16 lg:py-20 md:flex-1">
          <div className="max-w-31rem">
            <div className="mb-6 md:mb-10">
              <h5 className="mb-2 lato-style text-14px md:text-19px">
                {subheading}
              </h5>
              <h2 className="font-bluu text-32px md:text-47px leading-47 max-w-sm">
                {heading}
              </h2>
            </div>
            <div
              className="md:text-19px lato lato-prose mb-10 md:mb-16"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <ul className="m-0 space-y-6 relative z-10 mb-10">
              {listItems?.map((listItem, i) => (
                <li
                  key={i}
                  className="border-lightBlue pl-6 py-1"
                  style={{ borderLeftWidth: "3px" }}
                >
                  <p className="lato font-black text-18px md:text-25px">
                    {listItem.item}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="relative md:w-1/3">
          <div className="relative two-column-overflow">
            {image && (
              <GatsbyImage
                image={imageSource}
                alt={image?.altText}
                objectFit="cover"
                className="object-center"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default TwoColumns2022;
