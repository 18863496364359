import React from "react";
import Button2022 from "./elements/button";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Cta2022 = (props) => {
    const {sectionId, heading, content, button, logo, button2} = props;

    const logoSource = getImage(logo.localFile);

    return (
        <section className="overflow-hidden bg-darkBlue">
            <div className="container container-2022 flex flex-col md:flex-row">
                <div className="relative before-left before-blue text-white py-10 md:pr-6 md:py-14 md:flex-1">
                    <div className="max-w-md space-y-6 md:space-y-8">
                        <h2 className="font-bluu text-32px md:text-47px leading-47">
                            {heading}
                        </h2>
                        <div
                            className="md:text-19px lato lato-prose"
                            dangerouslySetInnerHTML={{__html: content}}
                        />
                        {!button ? null : (
                        <Button2022
                            colour="lightBlue-alt"
                            title={button.title}
                            url={button.url}
                            target={button.target}
                        />
                        )}
                    </div>
                </div>
                <div
                    className="relative before-right before-darkblue md:px-6 py-10 md:py-14 md:pl-8 lg:pl-12 flex flex-col items-center justify-center w-full md:w-1/3">
                    <div
                        className="flex flex-col flex-auto md:justify-end space-y-8 md:space-y-12 lg:pt-6">
                        <GatsbyImage
                            image={logoSource}
                            alt={logo.altText}
                            objectFit="contain"
                            className="object-contain object-center max-w-md"
                        />
                        {!button2 ? null : (
                        <Button2022
                            colour="lightBlue-alt"
                            title={button2.title}
                            url={button2.url}
                            target={button2.target}
                        />
                            )}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Cta2022;
