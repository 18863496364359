import React, { useEffect, useContext } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";
import LineWormhole from "./LineWormhole";
import AppContext from "../contexts/AppContext";
import moment from "moment";
import CTA from "./CTA";

const Hero = (props) => {
  const {
    date,
    startTime,
    endTime,
    heading,
    content,
    button,
    logos,
    sectionId,
  } = props;

  // TODO: one for future reference, this setActiveAnchor stuff can be converted to a component with {children} — see https://mxstbr.blog/2017/02/react-children-deepdive/ for reference
  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0 });

  const dateCorrected = moment(date, "DD/MM/YYYY").toISOString();

  useEffect(() => {
    if (inView) setActiveAnchor(sectionId);
  }, [inView]);

  return (
    <div
      ref={ref}
      className="relative bg-white overflow-hidden"
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
    >
      <div
        className="container relative z-10 flex flex-col items-center justify-center prose lg:h-screen pt-36 lg:pt-0 lg:mt-0 pb-36 lg:pb-0 lg:prose"
        style={{ maxHeight: "800px", minHeight: "600px" }}
      >
        <div className="flex font-serif font-medium text-16px lgtext-20px">
          {date && (
            <p className="px-5 py-2 border border-r-0 border-pearl ">
              {moment(dateCorrected).locale("en-gb").format("Do MMM YYYY")}
            </p>
          )}
          {startTime && (
            <p className="px-5 py-2 border border-pearl">
              {startTime} - {endTime}
            </p>
          )}
        </div>
        <h1
          className="flex flex-col items-center max-w-2xl my-6 text-center text-h1 navigate-text-h1">
          {heading}
        </h1>
        <div
          className="text-center text-18px lg:text-25px"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {/* {button && <Link to={button.url} target={button.target} className="flex px-8 py-5 mt-6 font-bold text-white uppercase transition border border-white text-14px bg-azure hover:bg-navy focus:outline-none hover:text-white">{button.title}</Link>} */}
        {button && <CTA className="mt-6 text-14px" filled {...button} />}
      </div>
      {logos && (
        <div className="container relative z-10 grid justify-center max-w-4xl grid-flow-row grid-cols-2 gap-12 pb-12 mx-auto sm:grid-cols-3 lg:grid-cols-6 gap-y-6 sm:gap-y-12 sm:gap-6 ">
          {logos.map(({ logo: { altText, localFile } }, index) => {
            const imageSource = getImage(localFile);
            return (
              <div
                key={`${`logo`}-${index}`}
                className="flex items-center justify-center h-20"
              >
                <div>
                  <GatsbyImage
                    image={imageSource}
                    alt={altText}
                    objectFit="contain"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <LineWormhole />
    </div>
  );
};

export default Hero;
