import React, { useState, useContext } from "react";
import Host from "./PlanningHost";
import AppContext from "../../contexts/AppContext";
import ExpandSVG from "../../assets/images/expand.svg";
import { motion, AnimatePresence } from 'framer-motion';

const PlanningItem = ({
  startTime,
  endTime,
  heading,
  content,
  hosts,
  hostsLabel,
  video,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setShowModal, setModalVideo } = useContext(AppContext);

  return (
    <li className="flex flex-col lg:flex-row border-b border-pearl">
      <div className={`w-full lg:w-1/4 ${(startTime && endTime) ? 'border-b' : 'pt-0'} lg:border-b-0 lg:border-r border-pearl p-5 lg:py-10 lg:px-5`}>
        {(startTime && endTime) && (
          <p className="font-bold text-16px">
            {startTime} - {endTime}
          </p>
        )}
      </div>
      <div className="w-full lg:w-3/4 px-5 lg:px-10">
        <div
          className="flex justify-between items-center cursor-pointer py-5 lg:py-10 lg:pb-5"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="font-medium font-serif text-20px leading-tight">
            {heading}
          </p>
          <div className="ml-6 flex-shrink-0">
            <img
              src={ExpandSVG}
              alt="expand"
              className={`${isOpen && "transform rotate-180"
                } transition-transform duration-150 ease-in-out`}
            />
          </div>
        </div>

        <div className="mb-5 lg:mb-10">
          {hostsLabel && <p className="font-bold text-16px mb-6">{hostsLabel}</p>}
          <div className="flex flex-wrap space-x-6">
            {hosts?.map((host) => (
              <Host
                key={host.name}
                image={host.profilePicture}
                name={host.name}
                company={host.company}
                linkedin={host.linkedinLink}
              />
            ))}
          </div>
          <AnimatePresence >
            {isOpen && (
              <motion.div
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className="text-14px text-iron mb-6"
                  dangerouslySetInnerHTML={{ __html: content }}
                />


                {video && (
                  <button
                    className="flex text-12px bg-azure transition hover:bg-navy text-white py-4 px-5 font-bold uppercase focus:outline-none"
                    onClick={() => {
                      setModalVideo(video);
                      setShowModal(true);
                    }}
                  >
                    Watch the session
                  </button>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </li>
  );
};

export default PlanningItem;
