import React, { useEffect, useContext, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import slugify from "slugify";

import Speaker from "./Speaker";
import AppContext from "../contexts/AppContext";

const TwoColumnSlider = (props) => {
  const { intro, sectionId, slides } = props;

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0.5 });
  
  const [selectedTab, setSelectedTab] = useState(0);
  const [clicked, setClicked] = useState(false);
  let timeout;

  useEffect(() => {
    if (!clicked && !timeout) {
      timeout = setTimeout(() => setSelectedTab((selectedTab + 1) === slides?.length ? 0 : selectedTab + 1), 6000);
    } else {
      clearTimeout(timeout);
    }

    if (inView) {
      setActiveAnchor(sectionId);
    }

    return () => timeout && clearTimeout(timeout);
  }, [inView, selectedTab]);


  return (
    <div
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="bg-powder py-28 lg:py-32">
      <div className="container flex flex-col gap-y-14">
        <header className="flex max-w-xl">
          <div className="w-auto pr-8 prose lg:prose">
            {intro.subheading && <p className="text-14px uppercase text-azure font-bold mb-6">{intro.subheading}</p>}
            {intro.heading && <h2 className="mb-4">{intro.heading}</h2>}
            {intro.content && <p className="text-18px">{intro.content}</p>}
          </div>
        </header>
        {slides && (
          <div className="grid grid-cols-12 md:items-center">
            <div className="col-span-12 md:col-span-7">
            {slides?.map((slide, i) => (
              <React.Fragment key={`image${i}`}>
                {selectedTab === i && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 10, opacity: 0 }}
                      >
                        <div className="aspect-w-4 aspect-h-3">
                          <GatsbyImage className="w-full absolute" style={{position: 'absolute'}} imgClassName="absolute" image={getImage(slide.image.localFile)} alt={slide?.image?.altText} />
                        </div>
                      </motion.div>
                    </AnimatePresence>
                )}
              </React.Fragment>
            ))}

            </div>
            <div className="col-span-12 md:col-span-4 md:col-start-9" onClick={() => setClicked(true)}>
              <div className={`flex gap-8 md:hidden`}>
                {slides?.map((item, i) => (
                  <div className="w-full py-4 mt-4 mb-4 md:mt-0 md:py-6 md:mb-4 cursor-pointer" onClick={() => setSelectedTab(i)}>
                    <div className={`w-full h-0.5 bg-navy bg-opacity-25 relative overflow-hidden`} >
                      <div className={`absolute top-0 left-0 w-full h-full bg-azure transform -translate-x-full ${(!clicked && (selectedTab === i)) && 'animate-progress-x'} ${(clicked && (selectedTab === i)) && 'translate-x-0'}`} style={{animationDuration: '6s'}}></div>
                    </div>
                  </div>
                ))}
              </div>

              {slides?.map((item, i) => (
                  <div
                    key={`textslide${i}`}
                    className={`flex flex-col max-w-[24rem] md:py-4 select-none cursor-pointer ${((selectedTab !== i)) && 'hidden md:block'}`}
                    onClick={() => setSelectedTab(i)}
                  >
                  <div className={`py-2 md:pl-8 relative ${!clicked && selectedTab === i && 'border-blue'}`}>
                    <div className=" h-full w-0.5 bg-navy bg-opacity-25 hidden md:block absolute top-1/2 transform -translate-y-1/2 left-0 overflow-hidden">
                      <div
                        className={`absolute top-0 left-0 w-full h-full bg-azure transform -translate-y-full ${
                          !clicked && selectedTab === i && ' animate-progress-y'
                        } ${clicked && selectedTab === i && 'translate-y-0'}`}
                        style={{ animationDuration: '6s' }}
                      ></div>
                    </div>
                    <div className="flex flex-col prose">
                      <h4 className="font-bold " style={{fontSize: '1.5rem'}}>{item.heading}</h4>
                      <AnimatePresence >
                      {selectedTab === i && (
                        <motion.div
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                          }}
                          transition={{ duration: 0.3 }}
                        >
                          <div className="text-15px mt-4">
                            <p>{item.content}</p>
                          </div>
                        </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default TwoColumnSlider;
