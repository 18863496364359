import React, { useEffect, useContext, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, FreeMode} from 'swiper';
import "swiper/css";
import "swiper/css/free-mode";
import 'swiper/css/navigation'
import { useInView } from "react-intersection-observer";
import slugify from "slugify";

import Speaker from "./Speaker";
import AppContext from "../contexts/AppContext";

const Speakers = (props) => {
  const { subheading, heading, speaker, sectionId } = props;

  const { setActiveAnchor } = useContext(AppContext);

  const { ref, inView } = useInView({ threshold: 0.5 });


  SwiperCore.use([Navigation, FreeMode]);

  useEffect(() => {
    if (inView) {
      setActiveAnchor(sectionId);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id={slugify(sectionId, {
        lower: true,
        strict: true,
      })}
      className="bg-powder py-28 lg:py-32 prose lg:prose-lg overflow-hidden"
    >
      <div className="container">
        <header className="flex">
          <div className="w-auto pr-8">
            {subheading && <p className="text-14px uppercase text-azure font-bold mb-6">{subheading}</p>}
            {heading && <h3>{heading}</h3>}
          </div>
          <div className="ml-auto flex gap-x-3">
            <button className="swiper-prev" >
              <div className="w-12 h-12 bg-navy block rounded-full relative">
                <div className="w-2.5 h-2.5 border-l-2 border-b-2 border-white absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-45"></div>
              </div>
            </button>
            <button className="swiper-next">
              <div className="w-12 h-12 bg-navy block rounded-full relative">
                <div className="w-2.5 h-2.5 border-r-2 border-b-2 border-white absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45"></div>
              </div>
            </button>
          </div>
        </header>
          <Swiper
              navigation={{
                "prevEl": '.swiper-prev',
                "nextEl": '.swiper-next',
              }}
              freeMode
              className="w-full flex flex-col relative mt-12"
              slidesPerView={`auto`}
              grabCursor="1"
              freeMode={true}
              style={{ overflow: `visible` }}>
            {speaker.map(
              (
                {
                  name,
                  companyLogo,
                  companyPosition,
                  linkedinLink,
                  profilePicture,
                  startTime,
                  endTime,
                  topic
                },
                index
              ) => (
                <SwiperSlide 
                  key={name}
                  className={`max-w-xs mr-6 last:mr-0 flex flex-col justify-center h-full`}
                  >
                  <Speaker
                    name={name}
                    profilePicture={profilePicture}
                    linkedin={linkedinLink}
                    company={companyPosition}
                    companyLogo={companyLogo}
                    topic={topic}
                    startTime={startTime}
                    endTime={endTime}
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
      </div>
    </div>
  );
};

export default Speakers;
