import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import Background from "../../assets/images/2022/map-background.png";
import compassBackground from "../../assets/images/2022/compass-background-alt.svg";
import compassArrow from "../../assets/images/2022/compass-arrow-alt.svg";
import {
  useViewportScroll,
  useAnimation,
  useTransform,
  motion,
} from "framer-motion";
import { useInView } from "react-intersection-observer";

const ShortIntroduction = (props) => {
  const { stats, sectionId } = props;
  const { scrollYProgress } = useViewportScroll();
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 360]);
  const { ref, start, end } = useRefScrollProgress();
  const typingProgress = useTransform(scrollYProgress, [start, end], [0, 1.3]);

  const inViewOpts = useInView({ threshold: 0 });
  const ref2 = inViewOpts.ref;
  const inView = inViewOpts.inView;
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("open");
    }
  }, [inView]);

  return (
    <section ref={ref} id={sectionId}>
      <div className="flex flex-col md:flex-row">
        <div className="bg-darkBlue w-full md:w-1/4">
          <div className="flex items-center justify-center p-8 lg:p-16">
            <div className="relative inline-block">
              <img className="relative" src={compassBackground} alt="" />
              <motion.img
                style={{ rotate: rotate }}
                className="absolute top-0 left-0 w-full h-full"
                src={compassArrow}
              />
            </div>
          </div>
        </div>
        <div className="relative w-full md:w-3/4 text-darkBlue intro-stats px-6 pt-8 pb-12 md:p-12 md:pt-4 lg:p-20 lg:pt-12 lg:pb-10 xl:pl-32 xl:pt-4 xl:pb-16 relative overflow-hidden">
        <div className="stone-overlay-intro-short"></div>
          <ul
            ref={ref2}
            className="m-0 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 xl:space-x-10 relative z-10"
          >
            {stats?.map((stat, i) => (
              <li key={i} className="text-center">
                <motion.div
                  variants={{
                    open: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.6,
                        delay: i / 4 + 0.2,
                      },
                    },
                    closed: { opacity: 0, y: 20 },
                  }}
                  initial={"closed"}
                  animate={controls}
                  className="font-bluu text-display-sm leading-none"
                >
                  {stat.stat}
                </motion.div>
                <motion.div
                  className="bg-darkBlue mb-5 mx-auto"
                  style={{ height: "5px" }}
                  variants={{
                    open: {
                      opacity: 1,
                      width: "85%",
                      transition: {
                        duration: 0.6,
                        delay: i / 4 + 0.3,
                      },
                    },
                    closed: { opacity: 0, width: 0 },
                  }}
                  initial={"closed"}
                  animate={controls}
                />
                <p
                  style={{ maxWidth: "95px" }}
                  className="lato-style text-12px mx-auto text-center"
                >
                  {stat.caption}
                </p>
              </li>
            ))}
          </ul>
          <img
            className="absolute opacity-20 md:opacity-100 w-full h-auto md:h-full md:object-cover right-0 bottom-0 left-0 z-negative"
            src={Background}
          />
        </div>
      </div>
    </section>
  );
};

function useRefScrollProgress(inputRef) {
  const ref = inputRef || useRef();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = rect.top + scrollTop;
    setStart(offsetTop / document.body.clientHeight);
    setEnd((offsetTop + rect.height) / document.body.clientHeight);
  });
  return { ref, start, end };
}

export default ShortIntroduction;
