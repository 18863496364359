import React from "react";
import Social from "./Social";
import Logo from "./LogoFooter";

const Footer = ({social, footer: {copyright, links}}) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-darkBlue">
            <div className="container bg-darkBlue container-2022 py-14 lg:py-20">
                <div className="flex flex-col-reverse md:flex-row justify-between items-center">
                    <p className="text-14px flex flex-col-reverse md:flex-row justify-between items-center md:w-3/5 space-y-3 md:space-y-0">
                        <span className="lato text-22px pt-3 text-white  md:pt-0">{`© ${copyright} ${currentYear}`}</span>
                        {links?.map(({link}) => (
                            <a
                                key={link.url}
                                href={`https://www.zencargo.com${link.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="no-underline text-white text-medium hover:opacity-50 transition-opacity duration-300 ease-in-out lato text-22px"
                            >
                                {link.name}
                            </a>
                        ))}
                    </p>
                    <Logo className=""/>
                    {/* <Social className="flex mb-6 md:mb-0" {...social} /> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
