import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import EventDate from "./EventDate";
import EventTime from "./EventTime";
import Logo from "./Logo";
import LogoWhite from "./LogoWhite";
import CTA from "./CTA";
import AppContext from "../contexts/AppContext";

const Header = ({ header, social, hideTime }) => {
  const [scrolled, setScrolled] = useState(false);
  const [headerButton, setHeaderButton] = useState(header.button);
  const [beyond, setBeyond] = useState(false);

  // console.log(hideTime);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.includes("/typ")) {
        setHeaderButton(header.typButton);
      }

      const beyondHeaderButton = {
        target: "",
        title: "Get in Touch",
        url: "https://www.zencargo.com/get-started/",
      };

      if (window.location.pathname.includes("/navigate-beyond-21")) {
        setHeaderButton(beyondHeaderButton);
        setBeyond(true);
      }
    }
  }, []);

  return (
    <header
      className={`absolute w-full top-2 lg:top-16 left-0 py-12 md:py-16 lg:py-6 z-30 transition-colors duration-300 ease-in-out`}
    >
      <div
        className="container flex items-center justify-between relative"
        style={{ maxWidth: "1312px" }}
      >
        {!hideTime && (
          <EventDate
            {...header}
            reverse={!scrolled}
            className={`hidden lg:flex ${!scrolled && "lg:-mt-8"}`}
          />
        )}
        {scrolled ? (
          <Logo className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        ) : (
          <LogoWhite className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        )}
        {!hideTime && (
          <EventTime
            {...header}
            reverse={!scrolled}
            className={`hidden lg:flex ${!scrolled && "lg:-mt-8"}`}
          />
        )}

        {headerButton?.url && (
          <div className="hidden lg:flex">
            <CTA
              className="text-14px"
              filled
              small
              beyond={beyond}
              {...headerButton}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
