import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Modal from "../components/Modal";
import Anchors from "../components/Anchors";
import Hero from "../components/Hero";
import Introduction from "../components/Introduction";
import Speakers from "../components/Speakers";
import Agenda from "../components/Agenda";
import Change from "../components/Change";
import Partners from "../components/Partners";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Videos from "../components/Videos";
import TwoColumnSlider from "../components/TwoColumnSlider";
import Blocks from "../components/Blocks";
import AppContext from "../contexts/AppContext";
// 2022 Components
import Hero2022 from "../components/2022/Hero";
import Introduction2022 from "../components/2022/Introduction";
import Speaker2022 from "../components/2022/Speakers";
import Agenda2022 from "../components/2022/Agenda";
import TwoColumns2022 from "../components/2022/TwoColumns";
import Cta2022 from "../components/2022/Cta";
import Quote2022 from "../components/2022/Quote";
import ShortIntroduction from "../components/2022/ShortIntroduction";
import Videos2022 from "../components/2022/Videos";
// 2023 Components
import Introduction2023 from "../components/2023/Introduction";

const LandingPage = (data) => {
  const {
    data: {
      wpVirtualEvent: {
        virtualEventFields: { global, header, footer, modal, seo },
      },
      content: {
        virtualEventFields: { pageType, hideDateAndTime, flexibleContent },
      },
    },
  } = data;
  //console.log(data);

  const [showModal, setShowModal] = useState(false);
  const [modalVideo, setModalVideo] = useState("");
  const [anchors, setAnchors] = useState([]);
  let [activeAnchor, setActiveAnchor] = useState([]);

  useEffect(() => {
    // Create anchors
    if (pageType === "childpage") return;
    flexibleContent?.forEach((section, index) => {
      if (section.sectionId !== "banner") {
        setAnchors((prev) => [...prev, section.sectionId]);
      }

      if (index === 0) setActiveAnchor(section.sectionId);
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        anchors,
        activeAnchor,
        setActiveAnchor,
        showModal,
        setShowModal,
        modalVideo,
        setModalVideo,
      }}
    >
      <SEO {...seo} />
      <Modal {...modal} />
      <Header
        header={header}
        social={global}
        hideTime={data?.data?.content?.virtualEventFields?.hideDateAndTime}
      />
      {/* Hide older design anchors sidebar */}
      {/* {pageType === "homepage" && <Anchors />} */}
      {flexibleContent?.map((section, index) => (
        <RenderFlexibleSection
          key={`${section.__typename}-${index}`}
          section={section}
        />
      ))}
      <Footer social={global} footer={footer} />
      <div
        style={{ display: "none" }}
        className="text-lightBlue desktop-line-break"
      ></div>
    </AppContext.Provider>
  );
};

const RenderFlexibleSection = ({ section }) => {
  switch (section.__typename) {
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Hero":
      return <Hero {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Introduction":
      return <Introduction {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Speakers":
      return <Speakers {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Agenda":
      return <Agenda {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Change":
      return <Change {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Partners":
      return <Partners {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Banner":
      return <Banner {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Videos":
      return <Videos {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_TwoColumnSlider":
      return <TwoColumnSlider {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_Blocks":
      return <Blocks {...section} />;
    // 2022 Components
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Hero":
      return <Hero2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Intro":
      return <Introduction2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Speaker":
      return <Speaker2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Agenda":
      return <Agenda2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022TwoColumns":
      return <TwoColumns2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Cta":
      return <Cta2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Quote":
      return <Quote2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022ShortIntroduction":
      return <ShortIntroduction {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Videos":
      return <Videos2022 {...section} />;
    case "WpVirtualEvent_Virtualeventfields_FlexibleContent_2023Intro":
      return <Introduction2023 {...section} />;
    default:
      return null;
  }
};

export const query = graphql`
  query ($slug: String!) {
    wpVirtualEvent(
      slug: { eq: "navigate-the-virtual-supply-chain-summit-2023" }
    ) {
      virtualEventFields {
        pageType
        hideDateAndTime
        global {
          socialIcons {
            url {
              title
              url
              target
            }
          }
        }
        header {
          date
          startTime
          endTime
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
          button {
            target
            url
            title
          }
          typButton {
            target
            url
            title
          }
        }
        footer {
          copyright
          links {
            link {
              name
              url
            }
          }
        }
        modal {
          heading
          content
          hubspotForm {
            formId
            portalId
            region
          }
        }
        seo {
          description
          title
          image {
            sourceUrl
          }
        }
      }
    }
    content: wpVirtualEvent(slug: { eq: $slug }) {
      virtualEventFields {
        pageType
        hideDateAndTime
        flexibleContent {
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Videos {
            __typename
            subheading
            heading
            content
            videos {
              heading
              content
              video
              hosts {
                company
                fieldGroupName
                linkedinLink
                name
                profilePicture {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
              videoPreviewImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      aspectRatio: 1.5
                      width: 600
                    )
                  }
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Hero {
            __typename
            sectionId
            button {
              target
              title
              url
            }
            content
            date
            endTime
            fieldGroupName
            heading
            startTime
            logos {
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                  }
                }
                altText
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Introduction {
            __typename
            sectionId
            heading
            content
            button {
              target
              title
              url
            }
            statsCard {
              fieldGroupName
              label
              number
            }
            subheading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Agenda {
            __typename
            sectionId
            content
            fieldGroupName
            heading
            subheading
            planningItem {
              content
              endTime
              heading
              isBreak
              startTime
              video
              hostsLabel
              hosts {
                company
                fieldGroupName
                linkedinLink
                name
                profilePicture {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Speakers {
            __typename
            sectionId
            fieldGroupName
            heading
            subheading
            speaker {
              companyLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              companyPosition
              endTime
              linkedinLink
              name
              startTime
              topic
              profilePicture {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Change {
            __typename
            sectionId
            content
            card {
              content
              heading
              number
            }
            heading
            subheading
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Partners {
            __typename
            sectionId
            heading
            subheading
            logos {
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Banner {
            __typename
            sectionId
            date
            endTime
            button {
              target
              title
              url
            }
            heading
            startTime
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_TwoColumnSlider {
            __typename
            sectionId
            intro {
              content
              subheading
              heading
            }
            slides {
              heading
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_Blocks {
            __typename
            sectionId
            heading
            subheading
            blocks {
              gridTopLeft {
                content
                heading
                button {
                  url
                  title
                  target
                }
              }
              gridBottomLeft {
                heading
                subheading
                link {
                  url
                  title
                  target
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
              gridTopRight {
                link {
                  target
                  url
                  title
                }
                heading
              }
              gridBottomRight {
                link {
                  target
                  url
                  title
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                heading
                subheading
              }
              gridBottomCentre {
                testimonial
                name
                company
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Hero {
            __typename
            sectionId
            heading
            subheading
            heroDate
            heroTime
            button {
              title
              url
              target
            }
            backgroundType
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            video {
              altText
              caption
              localFile {
                url
              }
            }
            popupVideo {
              altText
              caption
              localFile {
                url
              }
            }
            popUpLink {
              title
              url
              target
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Intro {
            __typename
            sectionId
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            subheading
            heading
            content
            link {
              title
              url
              target
            }
            stats {
              stat
              caption
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Speaker {
            __typename
            sectionId
            heading
            speakers {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              name
              information
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Agenda {
            __typename
            sectionId
            subheading
            heading
            content
            agendaItems {
              time
              heading
              speakerName
              description
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022TwoColumns {
            __typename
            sectionId
            subheading
            heading
            content
            listItems {
              item
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Cta {
            __typename
            sectionId
            heading
            content
            button {
              title
              url
              target
            }
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            button2 {
              title
              url
              target
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Quote {
            __typename
            sectionId
            quote
            name
            roleAndCompany
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022ShortIntroduction {
            __typename
            sectionId
            stats {
              stat
              caption
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2022Videos {
            __typename
            sectionId
            videos {
              title
              speakerNames
              videoEmbed
              thumbnail {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
          ... on WpVirtualEvent_Virtualeventfields_FlexibleContent_2023Intro {
            __typename
            sectionId
            subheading
            heading
            content
            button {
              title
              url
              target
            }
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            button2 {
              title
              url
              target
            }
          }
        }
      }
    }
  }
`;

export default LandingPage;
