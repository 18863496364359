import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LinkedinSVG from "../../assets/images/linkedin.svg";

const Host = ({ image, name, company, linkedin }) => {
  const imageSource = getImage(image?.localFile);
  return (
    <div className="flex items-center mb-6">
      {imageSource && (
        <div className="w-10 h-10 rounded-full overflow-hidden">
          <div className="w-full h-full overflow-hidden flex justify-center items-center rounded-full">
            <GatsbyImage
              image={imageSource}
              alt={image?.altText}
              objectFit="cover"
              objectPosition="center"
              className="w-10 h-10 rounded-full transform transform-gpu "
            />
          </div>
        </div>
      )}
      <div className="ml-4 block">
        <p className="font-bold text-14px my-1 leading-none">{name}</p>
        <p className="text-14px text-iron">{company}</p>
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <img src={LinkedinSVG} alt="linkedin" className="h-3 mt-2" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Host;
