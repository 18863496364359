import React, { useEffect, useRef } from 'react'

export default function LineWormhole() {

    const canvasRef = useRef(null)
    const canvasContainerRef = useRef(null)
    let canvas, ctx, canvasContainer, coldStartFrames, frameSpawnFrequency, tailSpawnAge, lineColor, lineAlpha, frame, tails = [], width, height, center;

    let lines = [
        {
            speed: 100,
            offsetX: -400,
            offsetY: -350,
            start: "742.202 158.034",
            curves: "C821.294 373.175 843.818 864.946 360.591 785.853C170.304 754.657 -114.481 294.083 52.5065 201.787C217.811 110.397 304.282 -97.7541 414.182 58.1005C543.241 241.01 693.271 24.8325 742.202 158.034",
            start2: "702.985 180.06",
            curves2: "C1196.18 361.934 450.821 908.201 376.389 548.079C339.367 368.924 -8.06937 479.731 2.41587 294.88C12.7717 110.029 169.274 125.951 324.61 35.4673C517.875 -78.5758 572.373 131.906 702.985 180.06",
        },
        {
            speed: 150,
            offsetX: -450,
            offsetY: -350,
            start: "697.951 964.692",
            curves: "C418.345 1029.29 -176.725 947.605 53.5619 266.971C144.175 -1.11467 775.49 -306.352 839.048 -43.185C901.96 217.263 1126.94 390.593 909.597 513.439C654.586 657.513 871.281 924.693 697.951 964.692",
            start2: "532.825 841.976",
            curves2: "C179.951 1559.37 -263.147 278.492 203.64 293.249C437.422 300.757 357.812 -244.864 586.545 -200.464C815.279 -156.063 733.08 69.952 816.703 323.798C921.167 640.815 623.956 656.607 532.825 841.976",
        },
        {
            speed: 400,
            offsetX: -650,
            offsetY: -350,
            start: "165.198 877.574",
            curves: "C-16.1573 605.993 -198.807 -66.3562 531.923 -112.828C819.684 -131.08 1376.57 413.376 1160.26 594.731C946.284 774.275 883.372 1089.74 675.221 909.936C430.954 698.807 277.429 1045.86 165.198 877.574",
            start2: "320.341 653.241",
            curves2: "C-497.637 584.246 496.777 -441.624 685.771 64.5148C780.397 317.973 1251.33 -2.02109 1309.58 261.922C1367.83 525.865 1122.53 535.185 923.695 734.146C675.285 982.426 531.728 670.976 320.341 653.241",
        }
    ]

    const initWormhole = () => {

        canvas = canvasRef.current;
        canvasContainer = canvasContainerRef.current
        ctx = canvas.getContext("2d");

        if (!ctx) {
            console.error('Wormhole: canvas not found');
            return
        }

        canvas.width = (canvasContainer.offsetWidth * 1)
        canvas.height = (canvasContainer.offsetHeight * 1)
        width = canvas.width
        height = canvas.height
        center = {x: width / 2, y: height / 2};

        // How many frames of simulation before we start render
        coldStartFrames = 90;
        // How many frames apart we spawn a line tail
        frameSpawnFrequency = 20;
        // How many frames a tail lives
        tailSpawnAge = 300;

        // Line color and alpha settings
        lineColor = "213, 221, 227";
        lineAlpha = 0.5;

        frame = 0;

        // Format lines into points
        setupLines();

        // Draw
        for (let i = 0; i < coldStartFrames; i++) {
            draw(false);
        }
        draw(true);

    }

    const setupLines = () => {

        // Format line information from constructor to something more workable.
        lines = lines.map(line => {

            const _start = line.start.split(" ");
            const start = { x: parseFloat(_start[0]) + line.offsetX, y: parseFloat(_start[1]) + line.offsetY };

            const _start2 = line.start2.split(" ");
            const start2 = { x: parseFloat(_start2[0]) + line.offsetX, y: parseFloat(_start2[1]) + line.offsetY };

            const points = formatPoints(line, line.curves);
            const points2 = formatPoints(line, line.curves2);

            return {
                start,
                points,
                start2,
                points2,
                speed: line.speed
            }
        })
    }

    // Turns path information into bezier curve points
    const formatPoints = (line, curves) => {
        return curves.split("C").map(curve => {
            const _values = curve.split(" ");
            if (_values.length !== 6) return;
            return {
                cp1x: parseFloat(_values[0]) + line.offsetX,
                cp1y: parseFloat(_values[1]) + line.offsetY,
                cp2x: parseFloat(_values[2]) + line.offsetX,
                cp2y: parseFloat(_values[3]) + line.offsetY,
                x: parseFloat(_values[4]) + line.offsetX,
                y: parseFloat(_values[5]) + line.offsetY,
            }
        }).filter(val => val);
    }
    // Used to cache line tails
    tails = [];
    const draw = (render) => {

        frame++;

        // Clear canvas
        ctx.clearRect(0, 0, width, height);

        // For each line setting update and draw
        ctx.lineWidth = 2.5;
        lines.forEach(line => {
            updateLine(line);
            ctx.strokeStyle = `rgba(${lineColor}, ${lineAlpha})`;
            if (render) {
                drawLine(line);
            }
            if (frame % frameSpawnFrequency === 0) {
                tails.push({ age: tailSpawnAge, start: line.start, points: line.points.map(point => ({ ...point })) });
            }
        })

        // Draw tails
        tails.forEach(line => {
            line.age--;
            ctx.strokeStyle = `rgba(${lineColor},${(lineAlpha / tailSpawnAge) * line.age})`;
            if (render) {
                drawLine(line);
            }
        })
        tails = tails.filter(line => line.age > 0);

        // Request next draw
        if (render) {
            window.requestAnimationFrame(() => {
                draw(true);
            });
        }

    }

    // Update line values before drawing
    const updateLine = (line) => {

        // Distance value between 0-1 for the stage of the animation
        const distance = (Math.sin(frame / line.speed) + 1) / -2;

        // Animation start point between a and b

        if (!line.originStart) line.originStart = { x: line.start.x, y: line.start.y };
        const startDistance = distanceCalc(line.originStart, { x: line.start2.x, y: line.start2.y });

        line.start.x = line.originStart.x + startDistance.x * distance;
        line.start.y = line.originStart.y + startDistance.y * distance;

        // Foreach point

        line.points.forEach((point, i) => {

            // We store the original point data in the origin so we can animate the main x/y

            const point2 = line.points2[i];
            if (!point.origin) point.origin = { ...point };

            // Animation start point between a and b 

            const pointDistance = distanceCalc(point.origin, point2);
            point.x = point.origin.x + (distance * pointDistance.x);
            point.y = point.origin.y + (distance * pointDistance.y);

            // Animation control point 1 between a and b 

            const cp1Distance = distanceCalc({ x: point.origin.cp1x, y: point.origin.cp1y }, { x: point2.cp1x, y: point2.cp1y });
            point.cp1x = point.origin.cp1x + (distance * cp1Distance.x);
            point.cp1y = point.origin.cp1y + (distance * cp1Distance.y);

            // Animation control point 2 between a and b 

            const cp2Distance = distanceCalc({ x: point.origin.cp2x, y: point.origin.cp2y }, { x: point2.cp2x, y: point2.cp2y });
            point.cp2x = point.origin.cp2x + (distance * cp2Distance.x);
            point.cp2y = point.origin.cp2y + (distance * cp2Distance.y);

        });
    }
    const drawLine = (line) => {

        const points = line.points;

        // Start path

        const start = line.start;
        ctx.beginPath();
        ctx.moveTo(localX(start.x), localY(start.y));

        // Draw bezier curve between each point

        points.forEach(point => {
            ctx.bezierCurveTo(localX(point.cp1x), localY(point.cp1y), localX(point.cp2x), localY(point.cp2y), localX(point.x), localY(point.y));
        })

        // Finish line

        ctx.stroke();

    }
    // Convert positions to relative from center
    const localX = (x) => {
        return x + center.x;
    }
    const localY = (y) => {
        return y + center.y;
    }
    const distanceCalc = (p1, p2) => {
        const a = p1.x - p2.x;
        const b = p1.y - p2.y;
        return { x: a, y: b, full: Math.sqrt(a * a + b * b) };
    }

    useEffect(() => {
        initWormhole();
    }, [])

    return (
        <div ref={canvasContainerRef} className="absolute inset-0" style={{top: '-5%', left: '-2.5%'}}>
            <canvas ref={canvasRef} className="" ></canvas>
        </div>
    )
}


