import React from "react";
import LogoSvg from "../assets/images/logo.svg";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Logo = ({ className }) => {
  return (
    <a href="https://www.zencargo.com" target="_blank" className={className}>
      <div>
        <img src={LogoSvg} alt="" className="w-72 h-auto" />
      </div>
    </a>
  );
};

export default Logo;
