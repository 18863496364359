import React, { useContext, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import slugify from "slugify";
import AppContext from "../contexts/AppContext";
import ScrollToPlugin from "../assets/js/ScrollToPlugin";
import { useViewportScroll } from "framer-motion";
gsap.registerPlugin(ScrollToPlugin);

const Anchors = ({ className }) => {
  const { anchors } = useContext(AppContext);
  const [activeAnchor, setActiveAnchor] = useState(null);

  const yAnchorOffset = 400;

  useLayoutEffect(() => {

    if(anchors && !activeAnchor){
      setActiveAnchor(anchors[0]);
    }

    const anchorPositions = anchors.map(anchor => {
      const el = document.getElementById(slugify(anchor, {
        lower: true,
        strict: true,
      }));
      return {
        anchor: anchor,
        el: el,
        top: el ? el.offsetTop - yAnchorOffset : Infinity
      }
    })

    const handleScroll = () => {
      const currentPos = window.scrollY;
      let i;
      for(i = 0; i < anchorPositions.length; i++){
        if(anchorPositions[i].top > currentPos) break;
      }
      const currentActive = anchorPositions[i - 1].anchor;
      if(activeAnchor !== currentActive){
        setActiveAnchor(currentActive);
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [activeAnchor, anchors])

  const handleClick = (el) => {
    const anchor = `#${el.currentTarget.dataset.anchor}`;
    gsap.to(window, {
      duration: 1.25,
      scrollTo: { y: anchor, offsetY: (yAnchorOffset - 300) },
      ease: "power4.inOut",
    });
  };

  return anchors && anchors.length > 0 && (
    <ul
      className={`${className} hidden lg:block fixed z-40 top-1/2 transform -translate-y-1/2 right-0 text-12px font-bold uppercase text-right blend-difference`}
    >
      {anchors &&
        anchors?.map((anchor, index) => (
          <div key={`anchor-${index}`}>
          {anchor && (
            
              <li
                key={`${anchor?.toUpperCase()}-${index}`}
                className="py-3 flex items-center justify-end text-white h-10 cursor-pointer group"
                onClick={handleClick}
                data-anchor={slugify(anchor, {
                  lower: true,
                  strict: true,
                })}
              >
                <span
                  className={`${
                    anchor === activeAnchor
                      ? ""
                      : "opacity-0 transition group-hover:opacity-100 duration-300 h-10 flex items-center"
                  }`}
                >
                  <span className="mr-1">{`0${index + 1}.`}</span>
                  <span>{anchor}</span>
                </span>
                <span
                  className={`${
                    anchor === activeAnchor
                      ? "w-10 bg-white"
                      : "w-5 opacity-70 mr-5 bg-iron group-hover:opacity-100 group-hover:bg-white transition-colors duration-300"
                  } h-px ml-2`}
                ></span>
              </li>
            )}
          </div>
        ))}
    </ul>
  );
};

export default Anchors;
