import React from "react";
import Helmet from "react-helmet";

const SEO = ({ title, description, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="author" content="Zencargo" />

      <meta name="title" property="og:title" content={title} />
      <meta name="description" property="og:description" content={description} />
      <meta property="og:url" content="https://navigate.zencargo.com" />
      <meta name="image" property="og:image" content={image?.sourceUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:width" content="627" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={`zencargoltd`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image?.sourceUrl} />
    </Helmet>
  );
};

export default SEO;
