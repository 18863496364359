import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import Background from "../../assets/images/2022/pattern-background.png";
import compassBackground from "../../assets/images/2022/compass-background.svg";
import compassArrow from "../../assets/images/2022/compass-arrow.svg";
import {
  useViewportScroll,
  useAnimation,
  useTransform,
  motion,
} from "framer-motion";
import Button2022 from "../2022/elements/button";
import { useInView } from "react-intersection-observer";

const Introduction2022 = (props) => {
  const { icon, subheading, heading, content, link, stats, sectionId } = props;
  const { scrollYProgress } = useViewportScroll();
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 360]);
  const { ref, start, end } = useRefScrollProgress();
  const typingProgress = useTransform(scrollYProgress, [start, end], [0, 1.3]);

  const inViewOpts = useInView({ threshold: 0 });
  const ref2 = inViewOpts.ref;
  const inView = inViewOpts.inView;
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("open");
    }
  }, [inView]);

  return (
    <section ref={ref} id={sectionId}>
      <div className="flex flex-col md:flex-row">
        <div className="bg-lightBlue w-full md:w-1/4">
          <div className="bg-stone flex items-center justify-center p-8 lg:p-16">
            <div className="relative inline-block">
              <img className="relative" src={compassBackground} alt="" />
              <motion.img
                style={{ rotate: rotate }}
                className="absolute top-0 left-0 w-full h-full"
                src={compassArrow}
              />
            </div>
          </div>
        </div>
        <div className="bg-darkBlue text-white w-full md:w-2/5 px-6 md:p-12 md:px-12 py-8 md:py-16 lg:py-28">
          <h5 className="mb-2 lato-style text-14px md:text-19px">
            {subheading}
          </h5>
          <h2
            className="font-bluu text-32px md:text-47px leading-47 mb-6 md:mb-12"
            style={{ maxWidth: "420px" }}
          >
            {heading}
          </h2>
          <div
            className="md:text-19px lato lato-prose max-w-404"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <hr className="thick-hr mt-6 mb-8 md:mt-12 md:mb-16 lg:mt-20 md:mt-24" />
          {link && (
            <Button2022
              colour="grad-blue"
              title={link.title}
              url={link.url}
              target={link.target}
              typing={true}
              typingProgress={typingProgress}
              popup={true}
            />
          )}
        </div>
        <div className="relative w-full md:w-2/5 text-darkBlue intro-stats px-6 py-8 md:p-12 lg:p-20 lg:pt-36 background-bottom relative overflow-hidden">
          <ul
            ref={ref2}
            className="m-0 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 xl:space-x-10 relative z-10"
          >
            {stats?.map((stat, i) => (
              <li key={i} className="text-center">
                <motion.div
                  variants={{
                    open: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.6,
                        delay: i / 4 + 0.2,
                      },
                    },
                    closed: { opacity: 0, y: 20 },
                  }}
                  initial={"closed"}
                  animate={controls}
                  className="font-bluu text-display leading-none"
                >
                  {stat.stat}
                </motion.div>
                <motion.div
                  className="bg-darkBlue mb-3 lg:mb-6 mt-3"
                  style={{ height: "5px" }}
                  variants={{
                    open: {
                      opacity: 1,
                      width: "100%",
                      transition: {
                        duration: 0.6,
                        delay: i / 4 + 0.3,
                      },
                    },
                    closed: { opacity: 0, width: 0 },
                  }}
                  initial={"closed"}
                  animate={controls}
                />
                <p
                  style={{ maxWidth: "200px" }}
                  className="lato-style text-16px mx-auto text-center"
                >
                  {stat.caption}
                </p>
              </li>
            ))}
          </ul>
          <img
            className="absolute opacity-20 md:opacity-100 w-full h-auto right-0 bottom-0 left-0 z-negative"
            src={Background}
          />
        </div>
      </div>
    </section>
  );
};

function useRefScrollProgress(inputRef) {
  const ref = inputRef || useRef();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = rect.top + scrollTop;
    setStart(offsetTop / document.body.clientHeight);
    setEnd((offsetTop + rect.height) / document.body.clientHeight);
  });
  return { ref, start, end };
}

export default Introduction2022;
